// pages/404.js
import React from 'react';
import styled from 'styled-components';
import { themeState } from 'styleguide/theme';
import { DisplayXS, TextSMRegular } from 'styleguide/Texts';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  gap: 20px;
  flex-direction: row;
`;

const Divider = styled.div`
  height: 45px;
  width: 1px;
`;

export default function Custom404() {
  return (
    <Container>
      <DisplayXS>404</DisplayXS>
      <Divider
        style={{
          backgroundColor: themeState.current.neutral300,
        }}
      />
      <TextSMRegular
        style={{
          color: themeState.current.primary,
        }}
      >
        This page could not be found.
      </TextSMRegular>
    </Container>
  );
}
